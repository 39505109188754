import { prompts, toasts } from 'components/alert';
import { REFERRAL_BONUS_USD } from 'lib/constants/listings';
import { DocId } from 'schemas/types';

const handleCopyToClipboard = ({
  clearReferralQueryParam = true,
  userId,
}: {
  clearReferralQueryParam?: boolean;
  userId?: DocId;
}) => {
  const referralLink = `https://wanderglade.com/host?ref=${userId}`;
  navigator.clipboard
    .writeText(referralLink)
    .then(() => {
      if (clearReferralQueryParam) {
        const url = new URL(window.location.href);
        url.searchParams.delete('show_ref');
        window.history.replaceState({}, document.title, url.toString());
      }
      toasts.success('Referral link copied to clipboard!');
    })
    .catch(() => {
      toasts.error('Failed to copy referral link.');
    });
};

const DEFAULT_REFERRAL_BODY = `Know someone in who'd like to host? Refer them to earn $${REFERRAL_BONUS_USD}!`;

export const showReferralModal = async ({
  clearReferralQueryParam = true,
  userId,
  title = 'Refer a host',
  deleteUrlParam = 'show_ref',
  body = DEFAULT_REFERRAL_BODY,
  isMobile = false,
}: {
  clearReferralQueryParam?: boolean;
  userId?: DocId;
  title?: string;
  deleteUrlParam?: string;
  body?: string;
  isMobile?: boolean;
}) => {
  const modalContent = (
    <div className="tw-flex tw-flex-col tw-items-center tw-rounded-lg">
      <h2 className="tw-text-3xl tw-text-ebony tw-font-bold tw-mb-4 tw-font-secondary tw-text-center">
        {title}
      </h2>
      <p className="tw-text-base tw-text-ebony tw-font-sans-serif tw-mb-6 tw-text-center">
        {body}*
      </p>
      <div className=" tw-py-4 tw-px-2 tw-bg-champagne tw-rounded-lg tw-border tw-border-dashed tw-border-dark-champagne tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-w-full">
        <p className=" tw-font-bold tw-text-xs md:tw-text-sm tw-truncate tw-font-sans-serif tw-text-ebony tw-w-full tw-text-center">
          https://wanderglade.com/host?ref={userId}
        </p>
      </div>
      <p className="tw-text-xxs tw-text-ebony tw-font-sans-serif mt-2">
        <a
          href="https://wanderglade.com/terms"
          target="_blank"
          rel="noreferrer"
          className=" tw-text-ebony tw-font-sans-serif"
        >
          *Terms and conditions apply
        </a>
      </p>
    </div>
  );

  const confirmed = await prompts.confirm(modalContent, {
    confirmMsg: isMobile ? 'Share Referral Link' : 'Copy Referral Link',
  });

  if (confirmed) {
    const smsBody = `Hey there! I thought your place would be great for Wanderglade. They're offering the first 100 listings 0% fees for the first year, and the lowest fees on the market after that. Sign up using my referral link: https://wanderglade.com/host?ref=${userId}`;
    if (isMobile) {
      const shareBody = {
        title: 'Wanderglade Referral',
        text: smsBody,
        url: `https://wanderglade.com/host?ref=${userId}`,
      };
      // Check if navigator can share URL
      if (navigator?.canShare(shareBody)) {
        navigator.share(shareBody);
      } else {
        window.open(`sms:?&body=${encodeURIComponent(smsBody)}`);
      }
    } else handleCopyToClipboard({ clearReferralQueryParam, userId });
  } else if (clearReferralQueryParam) {
    const url = new URL(window.location.href);
    url.searchParams.delete(deleteUrlParam);
    window.history.replaceState({}, document.title, url.toString());
  }
};
