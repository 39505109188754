import { prompts, toasts } from 'components/alert';
import { contactHelp } from 'lib/db';
import { useAuthUser } from './auth';

export const useContactSupport = () => {
  const authUser = useAuthUser();

  const promptForSupport = () =>
    prompts
      .prompt(
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-4 tw-w-full sm:tw-w-auto">
          <h3 className="tw-text-3xl tw-font-bold tw-text-center tw-text-dark-ebony">
            Get in Touch
          </h3>
          <p className="tw-text-center tw-my-5 tw-font-sans tw-text-sm tw-text-dark-ebony">
            Got any questions or comments? Feel free to message us! We typically respond to support
            inquiries within 24 to 48 hours.
          </p>
        </div>,
        {
          confirmMsg: 'Send',
          fields: [
            {
              label: 'Name',
              placeholder: 'E.g. Maria Smith',
              element: (
                <input
                  type="text"
                  name="name"
                  defaultValue={authUser?.displayName || ''}
                  disabled={!!authUser?.displayName}
                  required={!authUser?.displayName}
                />
              ),
            },
            {
              label: 'Email',
              placeholder: 'E.g. maria@email.com',
              element: (
                <input
                  type="email"
                  name="email"
                  defaultValue={authUser?.email || ''}
                  disabled={!!authUser?.email}
                  required={!authUser?.email}
                />
              ),
            },
            {
              label: 'Message',
              placeholder: 'Your message',
              element: <textarea name="message" required />,
            },
          ],
        },
      )
      .then((fields) => ({
        name: fields?.name || null,
        email: fields?.email || null,
        message: fields?.message || null,
      }));

  const handleContactSupport = async () => {
    const { name, email, message } = await promptForSupport();

    if (!name || !email || !message) {
      // User canceled the prompt or fields are missing
      return;
    }

    try {
      await contactHelp({
        name,
        email,
        message,
      });

      toasts.success('Your message has been sent successfully!');
    } catch (error) {
      console.error('Error sending support message:', error);
      toasts.error('An error occurred while sending your message. Please try again later.');
    }
  };

  return handleContactSupport;
};
