import { motion } from 'framer-motion';

export const Card = ({
  e,
}: {
  e: { iconSrc: string; title: string; description: string };
}): JSX.Element => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="bg-white rounded-lg shadow-md p-5"
    >
      <div
        className="flex items-center justify-center"
        style={{ width: 64, height: 64, backgroundColor: '#BFBDA3', borderRadius: 20 }}
      >
        <img src={e.iconSrc} width={32} height={32} alt={e.title} />
      </div>
      <h4 className="is-size-4 has-text-weight-bold my-3">{e.title}</h4>
      <p>{e.description}</p>
    </motion.div>
  );
};
