import { WHAT_IS_WANDERGLADE } from 'lib/constants/hosting';

import { Card } from './LandingCard';

export const WhatIsWanderglade = () => {
  return (
    <div className="section" style={{ zIndex: 5, color: '#565940' }}>
      <h2 className="is-size-2 has-text-weight-bold mb-6 tw-text-center sm:tw-text-left">
        What is Wanderglade?
      </h2>
      <div className="columns" style={{ gap: 30 }}>
        {WHAT_IS_WANDERGLADE.map((e, i) => (
          <div
            key={i}
            className="column font-secondary tw-px-4 tw-py-5 tw-my-3 tw-bg-champagne"
            style={{ borderRadius: 20 }}
          >
            <Card e={e} />
          </div>
        ))}
      </div>
    </div>
  );
};
