import { REFERRAL_BONUS_USD } from 'lib/constants/listings';
import { getPrivateProfile } from 'lib/db';
import Router, { getPath } from 'lib/router';

import clsx from 'clsx';
import { useIsMobile } from 'components/Responsive';
import Button from 'components/form/Button';
import { requireLogin } from 'components/form/LoginPrompt';
import { showReferralModal } from 'components/landing/ReferralModal';

const ReferHost = ({ noBackground }: { noBackground?: boolean }) => {
  const isMobile = useIsMobile();

  return (
    <section
      className={clsx('hero section tw-text-ebony', !noBackground && 'tw-bg-sage')}
      id="refer-host"
    >
      <div className="container tw-flex tw-flex-col tw-items-center tw-justify-center">
        {!noBackground && (
          <img
            src="/static/hosting/world-map.svg"
            alt="World Map"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
        <h2 className="tw-text-4xl tw-font-bold tw-text-center">
          Know someone who would like to host?{' '}
        </h2>
        <p className="tw-my-3 tw-font-secondary tw-text-brown tw-text-center">
          Refer them, you get <b className="tw-text-lg">${REFERRAL_BONUS_USD}</b> once they get
          their first booking.
        </p>
        <Button
          onClick={async () => {
            const fromPath = '/?show_ref=true';

            const authUser = await requireLogin(fromPath);
            if (!authUser) return;

            const privateProfile = await getPrivateProfile(authUser.uid);
            if (!privateProfile.onboarded) {
              Router.push(getPath('onboarding', { from: fromPath }));
              return;
            }

            showReferralModal({
              userId: authUser.uid,
              clearReferralQueryParam: true,
              isMobile,
            });
          }}
          className="tw-z-10 tw-w-full md:tw-w-auto"
        >
          Refer a host
        </Button>
      </div>
    </section>
  );
};

export { ReferHost };
