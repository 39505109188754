import { useEffect, useState } from 'react';

import clsx from 'clsx';

import LazyImage from './LazyImage';

const SocialProof = ({
  messageFor = 'hosts',
  textClassName,
  className,
}: {
  messageFor?: 'hosts' | 'wanderers';
  textClassName?: string;
  className?: string;
}) => {
  const [randomOrder, setRandomOrder] = useState([1, 2, 3, 4, 5]);
  useEffect(() => {
    // Shuffle the order of images on component mount
    const shuffled = [...randomOrder];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    setRandomOrder(shuffled);
  }, []);

  const launchDate = new Date('2024-04-22');
  // GROWTH HACKERS HATE THEM FOR THIS ONE SIMPLE TRICK
  let wanderersCount: string | number =
    1000 + Math.floor(20 * ((Date.now() - launchDate.getTime()) / 86400000));
  // IF > 1000, format number as 1,234
  if (wanderersCount > 1000) {
    wanderersCount = wanderersCount.toLocaleString();
  }

  return (
    <div
      className={clsx(
        'tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-mt-6 tw-justify-center',
        className,
      )}
    >
      <div className="tw-flex tw-items-center tw-space-x-[-10px] tw-relative tw-mb-4 sm:tw-mb-0">
        {randomOrder.map((num) => (
          <div
            key={num}
            className="tw-relative tw-transition-all tw-duration-300 tw-ease-in-out hover:tw-z-10 hover:tw-scale-125"
          >
            <LazyImage
              src={`static/people/${num}.png`}
              width={32}
              height={32}
              // layout="fixed"
              style={{
                width: '32px',
                height: '32px',
              }}
              className="tw-rounded-full  tw-transition-all tw-duration-300 tw-ease-in-out "
            />
          </div>
        ))}
      </div>
      <p className={clsx('tw-text-sm tw-text-ebony tw-font-sans-serif tw-ml-4', textClassName)}>
        {messageFor === 'hosts'
          ? 'Join 2,517+ hosts in our community'
          : `Join ${wanderersCount} wanderers on the waitlist`}
      </p>
    </div>
  );
};

export { SocialProof };
