import { useState } from 'react';
import clsx from 'clsx';

import { motion, AnimatePresence } from 'framer-motion';

import { useSequentialId } from 'lib/utils/sequentialId';

const FaqItem = ({ expanded: initExpanded = false, ...props }) => {
  const [expanded, setExpanded] = useState(initExpanded);
  const panelId = `faq-item-${useSequentialId()}`;

  const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setExpanded((current) => !current);
  };

  const variants = {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
      <li
        onClick={handleClick}
        style={{ marginTop: '0.25rem' }}
        className={clsx(expanded && 'tw-bg-sage')}
      >
        <h3
          className={clsx('is-size-5 has-text-weight-bold', !expanded && 'mb-0')}
          style={{
            color: '#3A3A3A',
          }}
        >
          <button
            className="button-reset"
            aria-controls={panelId}
            aria-expanded={expanded}
            type="button"
          >
            <span className="flex-1">{props.question}</span>
            <i className={clsx('fas', expanded ? 'fa-minus' : 'fa-plus')} aria-hidden="true" />
          </button>
        </h3>
        <AnimatePresence initial={false}>
          {expanded && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={variants}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
              id={panelId}
              aria-hidden={!expanded}
              style={{ overflow: 'hidden' }}
            >
              <p
                className="tw-text-ebony"
                style={{
                  color: '#3A3A3A',
                }}
              >
                {props.answer}
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </li>
      <style jsx>{`
        @import 'styles/variables';

        li {
          display: block;
          padding: 1rem;
          outline: none;
          color: $text;
          height: 100%;
          border-radius: 4px;
          cursor: pointer;

          &:hover,
          &:focus-within {
            transition: box-shadow 0.2s ease;
            box-shadow: 0 0 0 2px rgba(22, 23, 26, 0.1);
          }
        }

        h3 {
          text-align: left;
          display: flex;
        }

        button {
          width: 100%;
          display: flex;
          text-align: left;
          align-items: center;

          .fas {
            line-height: 2rem;
            margin-right: 1rem;
          }

          &:focus {
            outline: none;
            span {
              text-decoration: underline;
            }
          }
        }

        p {
          margin-top: 1rem;
        }
      `}</style>
    </>
  );
};

export default FaqItem;
