import Link from 'components/Link';
import Router from 'lib/router';

import { requireLogin } from './form/LoginPrompt';

const CTALink = ({
  href,
  children,
  loginRequired,
  fullWidthMobile = false,
  className = '',
}: {
  href: string;
  children: React.ReactNode;
  loginRequired?: boolean;
  fullWidthMobile?: boolean;
  className?: string;
}) => {
  const handleClick = async () => {
    const toPath = href;
    if (!(await requireLogin(toPath))) return;
    Router.push(toPath);
  };

  const buttonClassNames = `
    tw-transition tw-duration-300 tw-ease-in-out tw-transform
    hover:tw-text-white hover:tw-scale-105 active:tw-scale-100
    font-sans-serif tw-px-4 tw-py-2 tw-bg-[#E07A5F] tw-text-white
    tw-border tw-border-[#E07A5F]
    tw-rounded-lg tw-whitespace-nowrap
    ${fullWidthMobile ? 'tw-w-full sm:tw-w-auto' : ''}
  `;

  return (
    <div
      className={`tw-inline-block tw-flex tw-justify-center tw-items-centered tw-p-1 ${
        fullWidthMobile ? 'tw-w-full' : ''
      } ${className}`}
    >
      {loginRequired ? (
        <button className={buttonClassNames} onClick={handleClick} type="button">
          {children}
        </button>
      ) : (
        <Link href={href} className={buttonClassNames}>
          {children}
        </Link>
      )}
    </div>
  );
};

export default CTALink;
