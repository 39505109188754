const CheckedCircle = ({ size = 30 }: { size?: number }) => (
  <div
    className="flex items-center justify-center"
    style={{
      backgroundColor: '#E07A5F',
      width: size,
      height: size,
      borderRadius: '100%',
      flexShrink: 0,
    }}
  >
    <i className="fas fa-check fa-sm" aria-hidden="true" style={{ color: '#F2E0C9' }} />
  </div>
);

export { CheckedCircle };
