import { Fragment } from 'react';

import { CheckedCircle } from 'components/CheckedCircle';
import Logo, { LetterMarkLogo } from 'components/Logo';

type Feature = {
  title: string;
  description: string;
  wandergladeHas: boolean;
  competitorHas: boolean;
  comingSoon?: boolean;
};

export const FeaturesComparison: React.FC<{
  features: Feature[];
  title: string;
  description: string;
}> = ({ features, title, description }) => {
  return (
    <section className="section flex justify-center" style={{ color: '#565940' }}>
      <div className="container flex flex-col items-center">
        <h2 className="is-size-2 has-text-weight-bold tw-text-center">{title}</h2>
        <p className="mt-4 mb-6 tw-text-center sm:tw-text-left" style={{ color: '#734E40' }}>
          {description}
        </p>

        <div
          className="w-full font-secondary"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            rowGap: 20,
            color: '#565940',
          }}
        >
          <div style={{ gridColumn: 'span 3' }} />
          <p className="has-text-weight-bold tw-text-sm sm:tw-text-inherit tw-flex tw-justify-center tw-items-center">
            <LetterMarkLogo size={30} className="tw-block sm:tw-hidden" />
            <Logo size={24} className="tw-hidden sm:tw-block" />
          </p>
          <p
            className="has-text-weight-bold tw-text-sm sm:tw-text-inherit has-text-centered tw-ml-4 sm:tw-ml-0"
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Competitor
          </p>
          {features.map((e, i) => (
            <Fragment key={i}>
              <div style={{ gridColumn: 'span 5' }}>
                <hr className="mb-0 tw-bg-dark-champagne" />
              </div>
              <div className="px-3" style={{ gridColumn: 'span 3' }}>
                <h4 className="is-size-4 is-size-6-mobile mb-3 has-text-weight-bold">
                  {e.title}
                  {e.comingSoon && (
                    <span className="tw-top-0  tw-text-dark-ebony tw-font-sans-serif tw-right-0 tw-bg-dark-champagne sm:tw-ml-2 tw-ml-1 tw-text-xxs tw-font-bold tw-px-2 tw-py-1 tw-rounded-full">
                      Coming Soon
                    </span>
                  )}
                </h4>
                <p className="is-size-7-mobile tw-text-dark-ebony" style={{ color: '#734E40' }}>
                  {e.description}
                </p>
              </div>
              <div className="flex items-center justify-center">
                {e.wandergladeHas ? (
                  <CheckedCircle />
                ) : (
                  <i className="fas fa-times" aria-hidden="true" style={{ color: '#734E40' }} />
                )}
              </div>
              <div className="flex items-center justify-center">
                {e.competitorHas ? (
                  <CheckedCircle />
                ) : (
                  <i className="fas fa-times" aria-hidden="true" style={{ color: '#734E40' }} />
                )}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};
