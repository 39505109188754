export const WHAT_IS_WANDERGLADE = [
  {
    iconSrc: '/static/hosting/icons/what_1.svg',
    title: 'Curated Retreats',
    description:
      'Discover our handpicked selection of unique, verified accommodations nestled in nature, perfect for a peaceful escape from the everyday hustle and bustle.',
  },
  {
    iconSrc: '/static/hosting/icons/what_2.svg',
    title: 'Seamless Trip Planning',
    description:
      'Our intuitive platform makes it effortless to plan your entire getaway: from booking your dream rental and discovering local gems, to mapping out your adventures.',
  },
  {
    iconSrc: '/static/hosting/icons/what_3.svg',
    title: 'Inspiring Community',
    description:
      'Connect with a vibrant community of like-minded explorers, share maps, travel tips, and stories, and gain insider knowledge from our passionate hosts and seasoned adventurers.',
  },
];

export const WHY_WANDERGLADE = [
  {
    iconSrc: '/static/hosting/icons/why_1.svg',
    title: 'Low-fee Guarantee',
    description:
      "With Wanderglade, enjoy peace of mind knowing you're getting the most competitive fees around. Our low-fee guarantee ensures we match any lower fees you find, so you keep more of your earnings.",
  },
  {
    iconSrc: '/static/hosting/icons/why_3.svg',
    title: 'Trustworthy Guest Community',
    description:
      "Rest easy hosting with Wanderglade's ID-verified guest pool, backed by our cutting-edge AI security checks. For any concerns, our dedicated 24/7 support team is always ready to assist you.",
  },
  {
    iconSrc: '/static/hosting/icons/why_2.svg',
    title: 'Fast Earnings Payouts',
    description:
      "Your hard work pays off quickly with Wanderglade's fast payouts. Receive your funds promptly on the last day of a booking, or sometimes even on the first, ensuring a steady cash flow.",
  },
];

export const BENEFITS = [
  {
    title: '$1M Liability Coverage',
    description:
      'Protect your property with Wanderglade’s $1M insurance coverage. Our comprehensive policy safeguards your space from any unexpected incidents, ensuring you can host with confidence.',
    wandergladeHas: true,
    competitorHas: true,
  },
  {
    title: 'Round-the-Clock Support',
    description:
      "Whenever you need us, day or night, Wanderglade's dedicated 24/7 support team is here to assist. Hosting is hassle-free when you have a reliable partner ready to help with any inquiry or issue.",
    wandergladeHas: true,
    competitorHas: true,
  },
  {
    title: 'Best Fees in the Industry',
    description:
      "Maximize your earnings with Wanderglade's competitive fee structure. We offer the best rates in the industry, ensuring you keep more of your hard-earned money without sacrificing quality or support.",
    wandergladeHas: true,
    competitorHas: false,
  },
  {
    title: 'Verified Guest Pool',
    description:
      'Trust and safety come first at Wanderglade. Our ID verification process for guests, coupled with proprietary AI security checks, ensures that your property is shared with only the most trustworthy of travelers.',
    wandergladeHas: true,
    competitorHas: false,
  },
  {
    title: 'Effortless Listing Import',
    description:
      'Transitioning to Wanderglade is a breeze with our tool that imports your listing directly from sites like Hipcamp, Airbnb, or Booking.com. Save time and avoid the stress of manual entry while expanding your hosting presence.',
    wandergladeHas: true,
    competitorHas: false,
    comingSoon: true,
  },
  {
    title: 'AI-Enhanced Listing Optimization',
    description:
      "Create the perfect listing with real-time, AI-generated feedback that helps you optimize your content as you go. It's like having a personal editor guiding you to a more appealing presentation.",
    wandergladeHas: true,
    competitorHas: false,
  },

  {
    title: 'The Zen Host Newsletter',
    description:
      "Stay informed and inspired with our monthly newsletter, The Zen Host. Packed with tips, insights, and success stories, it's your go-to resource for optimizing your listing and maximizing your passive income potential.",
    wandergladeHas: true,
    competitorHas: false,
  },
  // Let's keep this one for later
  // {
  //   title: 'Guaranteed Earnings',
  //   description:
  //     'Choose to list exclusively with Wanderglade and enjoy the benefits of our Guaranteed Earnings. This exclusive offer is our way of investing in your success and ensuring a profitable partnership by guaranteeing a minimum amount of monthly earnings. Terms and Conditions apply.',
  //   wandergladeHas: true,
  //   competitorHas: false,
  // },
];

export const START_HOSTING = [
  {
    iconSrc: '/static/hosting/icons/start_1.svg',
    title: 'List Your Space',
    description:
      'Sign up for free and showcase your space. Set your price, choose your amenities, upload stunning photos, and outline the unique aspects of your property.',
  },
  {
    iconSrc: '/static/hosting/icons/start_2.svg',
    title: 'Manage Your Bookings',
    description:
      'Stay on top of your bookings with a calendar designed for hosts. Enjoy the flexibility to accept or decline requests and plan your hosting around your life.',
  },
  {
    iconSrc: '/static/hosting/icons/start_3.svg',
    title: 'Enjoy Quick Compensation',
    description:
      'Connect to our trusted payment gateway for fast and reliable transactions. Experience hassle-free transfers post-booking, keeping your finances in flow.',
  },
];

export const FAQS = [
  {
    question: 'Is my place a good fit for Wanderglade?',
    answer: `If your property offers a unique, serene escape from the everyday hustle and bustle, it's likely a great fit for Wanderglade. We feature a curated selection of accommodations, from cozy cabins and charming cottages to stunning glamping retreats and off-the-grid hideaways. Our focus is on providing guests with tranquil getaways in nature, so if your property aligns with that vision, we'd love to have you on board.`,
  },
  {
    question: 'Does Wanderglade provide insurance?',
    answer: `Absolutely! We understand the importance of protecting your property. That's why Wanderglade offers comprehensive liability coverage of up to $1 million for every booking. This insurance policy safeguards you against any unexpected incidents or accidents, giving you peace of mind while hosting guests through our platform.`,
  },
  // {
  //   question: 'What is the Minimum Earnings Guarantee Program?',
  //   answer: `With Wanderglade's exclusive guarantee, you get assured earnings when you list only with us, keep instant bookings on, and maintain high availability. It's our way of committing to your hosting success. Terms and Conditions apply.`,
  // },
  {
    question: 'What if my property gets damaged?',
    answer: `We understand that accidents can happen, and we're here to support you in the rare case of property damage. Our team works diligently to screen guests and promote responsible behavior, but in the event of any damage, we have a comprehensive Host Damage Protection program in place that covers up to $25,000. This program covers a wide range of potential issues, and our dedicated support team will guide you through the resolution process, ensuring a fair outcome. We're committed to maintaining trust and transparency within our community.`,
  },
  {
    question: 'How do I get paid?',
    answer: `We've partnered with Stripe, a leading and secure payment processing platform, to ensure smooth and reliable payouts for our hosts. Once a guest's booking is complete, your earnings will be transferred directly to your connected Stripe account. Payouts are typically processed on the last day of the booking, but in some cases, you may receive payment as early as the first day. Stripe's robust technology ensures that your financial information is kept safe and confidential, allowing you to focus on providing exceptional experiences for your guests.`,
  },
  {
    question: 'How do you vet your guests?',
    answer: `We implement a thorough guest screening process to ensure a trustworthy community. All guests are required to provide a valid government-issued ID and a current photo for identity verification. Additionally, we require guests to verify their phone number and email address. By taking these precautionary measures, we aim to create a safe and secure environment for both hosts and guests.`,
  },
  {
    question: 'How does Wanderglade decide on which listings get approved?',
    answer: `Our dedicated team meticulously reviews each property submission to ensure it meets our high standards. We evaluate properties based on their unique qualities, the completeness and accuracy of the listing information, available amenities, and overall alignment with our brand vision. We strive to curate a diverse selection of exceptional spaces that offer unforgettable experiences for our guests. Our approval process is designed to maintain the quality and integrity of our platform.`,
  },
  {
    question: 'My property is not in Upstate New York, should I apply?',
    answer: `While our initial launch is focused on properties in Upstate New York, we have exciting plans to expand to other regions in the near future. If your property offers a unique and tranquil getaway experience that aligns with our criteria, we highly encourage you to apply. By submitting your application, you'll be among the first to know about our expansion plans and potential opportunities in your area. We value your interest and look forward to reviewing your submission.`,
  },
  {
    question: 'How much does it cost to list my property on Wanderglade?',
    answer: `Listing your property on Wanderglade is completely free! We believe in providing our hosts with a simple and accessible platform to showcase their unique spaces. There are no upfront costs or hidden fees associated with listing. We only charge a modest service fee when a booking is confirmed, ensuring that you can start earning income from your property without any financial barriers. Our flexible fee structure is designed to support your success as a host.`,
  },
  {
    question: 'What fees does Wanderglade charge?',
    answer: `We charge a service fee that varies based on the popularity and performance of your listing. This means that the more bookings you secure, the lower your service fee percentage will be. As a special promotion for early adopters, we're offering an exclusive 0% fee for the first year of listing with us. This allows you to maximize your earnings and establish your presence on our platform without any service charges..`,
  },
  {
    question: 'Can I sync my booking calendar with Wanderglade?',
    answer: `Yes, you can sync your booking calendar with Wanderglade. This ensures that your availability is always up to date and helps prevent double bookings. Our platform supports integration with various calendar systems to make the process seamless and efficient.`,
  },
  {
    question: 'How can there be more than 2000 hosts on Wanderglade?',
    // Explain that Wanderglade is operated by Vanly, Co. a marketplace RV travelers to find and book overnight parking and
    // amenities. Wanderglade is a new product that is being launched by Vanly, Co. and is leveraging the existing community
    answer: `Wanderglade is operated by Vanly, a trusted marketplace that connects RV travelers with unique overnight parking options. Our existing community of over 2000 hosts has been built through Vanly's platform, and we're excited to introduce Wanderglade as a new offering within our network. By leveraging our established community and resources, we're able to provide a seamless experience for hosts and guests alike. We're committed to maintaining the same level of quality and service that our users have come to expect from Vanly, ensuring a positive experience for everyone involved.`,
  },
];

export const EARLY_BIRD_BENEFITS = [
  '0% fee for the first year',
  '$1M liability coverage',
  'All guests will be ID verified and 24/7 support',
];
