import Button from 'components/form/Button';
import { useContactSupport } from 'lib/hooks/useContactSupport';
import FaqItem from './FaqItem';

type FaqItemSchema = {
  question: string;
  answer: string;
};

const FaqSection = ({
  items,
  openFirst = false,
}: {
  title?: string;
  items: FaqItemSchema[];
  openFirst?: boolean;
}) => {
  const handleContactSupport = useContactSupport();

  return (
    <section style={{ position: 'relative', overflow: 'hidden' }}>
      <img
        src="/static/hosting/cover-3.png"
        style={{
          zIndex: 1,
          position: 'absolute',
          bottom: 0,
          objectPosition: 'left',
          objectFit: 'cover',
          minHeight: '80%',
          minWidth: '100%',
          opacity: '20%',
          pointerEvents: 'none',
        }}
        alt="Cover"
      />
      <div
        className="section columns"
        style={{ color: '#565940', zIndex: 2, position: 'relative' }}
      >
        <div className="column">
          <h2 className="is-size-2 has-text-weight-bold mb-6">
            Frequently Asked
            <br />
            Questions
          </h2>
        </div>
        <div className="column" style={{ zIndex: 5 }}>
          <ul className="is-multiline content" style={{ margin: 0 }}>
            {items.map((faq, i) => (
              <FaqItem
                key={i}
                question={faq.question}
                answer={faq.answer}
                expanded={i === 0 && openFirst}
              />
            ))}
          </ul>
          <div className="tw-mt-4 tw-flex tw-flex-row tw-items-center tw-justify-between sm:tw-bg-cyan-50/40 tw-p-2 tw-rounded">
            <span className="tw-text-sm tw-font-secondary tw-font-bold tw-text-gray-700">
              Have more questions?
            </span>
            <Button className="tw-text-sm" onClick={handleContactSupport}>
              Contact Support
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
